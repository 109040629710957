
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref,
  computed,
  onMounted
} from "@cloudfun/core";
import printJS from "print-js";
import { commafy } from "xe-utils";
export default defineComponent({
  props: {
    modelValue: Boolean,
    data: {
      type: Object as any,
      required: true
    }
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const title = computed(() => `列印訂單：${props.data.Number}`);
    const closeModal = () => {
      emit("dismiss");
    };

    const showModal = () => {
      doPrint();
    };

    const doPrint = () => {
      console.log("data.", props.data);
      printJS({
        printable: "printContent",
        type: "html",
        header: "",
        targetStyles: ["*"]
      });
    };

    const formatPrice = (value: any) => {
      return commafy(value);
      //   const val = (value / 1).toFixed(2).replace(".", ",");
      //   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    return { closeModal, title, showModal, doPrint, formatPrice };
  },
  methods: {}
});
