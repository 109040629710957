<template>
  <vxe-modal :model-value="modelValue" :title="title" width="70%" show-footer height="100vh" @close="closeModal"
    @hide="closeModal" @show="showModal">
    <div class="print" id="printContent">
      <div class="container">
        <div class="printPage">
          <div class="mt-3 mb-2">
            <div class="title printFontColor">出貨單</div>
          </div>
          <div class="printInfo">
            <div class="">
              <div class=" text-sm">
                <table class="w-full">
                  <tr>
                    <td class="w-36">訂單編號</td>
                    <td>{{ data.Number }}</td>
                    <td class="w-36">付款方式</td>
                    <td>
                      {{
                        Object.values($model.enums.PaymentType).find(
                          e => e.Value === data.Payments[0].Type
                        )?.Name
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-36">訂購人</td>
                    <td>{{ data.Shipping?.ReceiverName }}</td>
                    <td class="w-36">發票類型</td>
                    <td>{{ data.InvoiceType === 3 ? "三聯式" : "二聯式" }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">聯絡電話</td>
                    <td>{{ data.Shipping?.ReceiverTel }}</td>
                    <td class="w-36">公司抬頭</td>
                    <td>{{ data.InvoiceTitle }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">手機號碼</td>
                    <td>{{ data.Shipping?.ReceiverMobilePhone }}</td>
                    <td class="w-36">公司統編</td>
                    <td>{{ data.InvoiceTaxId }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">收件地址</td>
                    <td>
                      {{
                        `${data.Shipping?.ReceiverAddress?.City}${data.Shipping?.ReceiverAddress?.PostalCode}${data.Shipping?.ReceiverAddress?.District}${data.Shipping?.ReceiverAddress?.Line}`
                      }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="my-5">
                <table class="table w-full">
                  <thead class="">
                    <tr class="">
                      <th>商品編號</th>
                      <th>商品名稱</th>
                      <th class="text-right">單價</th>
                      <th class="text-center">數量</th>
                      <th class="text-right">小計</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in data.Items" :key="item.Id">
                      <td>{{ item.Product ? item.Product.Number : "" }}</td>
                      <td>
                        {{ item.Product ? item.Product.Name : item.Name }}
                      </td>
                      <td class="text-right">
                        {{ formatPrice(item.Price) }}元
                      </td>
                      <td class="text-center">{{ item.Quantity }}</td>
                      <td class="text-right">
                        {{ formatPrice(item.SalePrice) }}元
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>12-ESP12-20</td>
                      <td>URNEX 咖啡機清潔劑</td>
                      <td class="text-right">550元</td>
                      <td class="text-center">2</td>
                      <td class="text-right">1,100元</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              <div class=" text-sm">
                <table class="w-full">
                  <tbody style="text-align:right;">
                    <!-- <tr>
                      <td>小計</td>
                      <td class="w-36">
                        {{ data.SaleAmount }}元
                      </td>
                    </tr>

                    <tr>
                      <td>紅利折抵</td>
                      <td class="w-36">0元</td>
                    </tr>
                    <tr>
                      <td>折抵</td>
                      <td class="w-36">0元</td>
                    </tr> -->
                    <tr>
                      <td>運費</td>
                      <td class="w-36">{{ formatPrice(data.ShippingAmount) }}元</td>
                    </tr>
                    <tr>
                      <td>總計</td>
                      <td class="w-36">
                        {{ formatPrice(data.Amount) }}元
                      </td>
                    </tr>

                    <!-- <tr>
                      <td>本次增加紅利</td>
                      <td class="w-36">11點</td>
                    </tr>

                    <tr>
                      <td>目前累積紅利</td>
                      <td class="w-36">43點</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="cut-line"></div>

          <div class="">
            <div class="">
              <div class=" text-sm">
                <table class="w-full">
                  <tr>
                    <td class="w-36">訂單編號</td>
                    <td>{{ data.Number }}</td>
                    <td class="w-36">付款方式</td>
                    <td>
                      {{
                        Object.values($model.enums.PaymentType).find(
                          e => e.Value === data.Payments[0].Type
                        )?.Name
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-36">訂購人</td>
                    <td>{{ data.Shipping?.ReceiverName }}</td>
                    <td class="w-36">發票類型</td>
                    <td>{{ data.InvoiceType === 3 ? "三聯式" : "二聯式" }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">聯絡電話</td>
                    <td>{{ data.Shipping?.ReceiverTel }}</td>
                    <td class="w-36">公司抬頭</td>
                    <td>{{ data.InvoiceTitle }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">手機號碼</td>
                    <td>{{ data.Shipping?.ReceiverMobilePhone }}</td>
                    <td class="w-36">公司統編</td>
                    <td>{{ data.InvoiceTaxId }}</td>
                  </tr>
                  <tr>
                    <td class="w-36">收件地址</td>
                    <td>
                      {{
                        `${data.Shipping?.ReceiverAddress?.City}${data.Shipping?.ReceiverAddress?.PostalCode}${data.Shipping?.ReceiverAddress?.District}${data.Shipping?.ReceiverAddress?.Line}`
                      }}
                    </td>
                  </tr>
                </table>
              </div>

              <div class="my-5">
                <table class="table w-full">
                  <thead class="">
                    <tr class="">
                      <th>商品編號</th>
                      <th>商品名稱</th>
                      <th class="text-right">單價</th>
                      <th class="text-center">數量</th>
                      <th class="text-right">小計</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in data.Items" :key="item.Id">
                      <td>{{ item.Product ? item.Product.Number : "" }}</td>
                      <td>
                        {{ item.Product ? item.Product.Name : item.Name }}
                      </td>
                      <td class="text-right">
                        {{ formatPrice(item.Price) }}元
                      </td>
                      <td class="text-center">{{ item.Quantity }}</td>
                      <td class="text-right">
                        {{ formatPrice(item.SalePrice) }}元
                      </td>
                    </tr>
                    <!-- <tr>
                      <td>12-ESP12-20</td>
                      <td>URNEX 咖啡機清潔劑</td>
                      <td class="text-right">550元</td>
                      <td class="text-center">2</td>
                      <td class="text-right">1,100元</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              <div class=" text-sm">
                <table class="w-full">
                  <tbody style="text-align:right;">
                    <!-- <tr>
                      <td>小計</td>
                      <td class="w-36">
                        {{ data.SaleAmount }}元
                      </td>
                    </tr>

                    <tr>
                      <td>紅利折抵</td>
                      <td class="w-36">0元</td>
                    </tr>
                    <tr>
                      <td>折抵</td>
                      <td class="w-36">0元</td>
                    </tr>-->
                    <tr>
                      <td>運費</td>
                      <td class="w-36">
                        {{ formatPrice(data.ShippingAmount) }}元
                      </td>
                    </tr>

                    <tr>
                      <td>總計</td>
                      <td class="w-36">
                        {{ formatPrice(data.Amount) }}元
                      </td>
                    </tr>

                    <!-- <tr>
                      <td>本次增加紅利</td>
                      <td class="w-36">11點</td>
                    </tr>

                    <tr>
                      <td>目前累積紅利</td>
                      <td class="w-36">43點</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <vxe-button type="button" status="success" content="列印" @click="doPrint"></vxe-button>
      <vxe-button type="button" content="關閉" @click="closeModal"></vxe-button>
    </template>
  </vxe-modal>
</template>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref,
  computed,
  onMounted
} from "@cloudfun/core";
import printJS from "print-js";
import { commafy } from "xe-utils";
export default defineComponent({
  props: {
    modelValue: Boolean,
    data: {
      type: Object as any,
      required: true
    }
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const title = computed(() => `列印訂單：${props.data.Number}`);
    const closeModal = () => {
      emit("dismiss");
    };

    const showModal = () => {
      doPrint();
    };

    const doPrint = () => {
      console.log("data.", props.data);
      printJS({
        printable: "printContent",
        type: "html",
        header: "",
        targetStyles: ["*"]
      });
    };

    const formatPrice = (value: any) => {
      return commafy(value);
      //   const val = (value / 1).toFixed(2).replace(".", ",");
      //   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    return { closeModal, title, showModal, doPrint, formatPrice };
  },
  methods: {}
});
</script>

<style scoped>
@page {
  size: A4 portrait;
  margin: 5mm;
}

.print .container {
  height: 290mm !important;
  padding: 0 !important;
  margin: 0 auto !important;
  font-size: 12pt !important;
}

.printPage .cut-line {
  border-bottom: 1px dashed #333;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.printPage .table {
  border-color: #cbcbcb;
}

.printPage .table tbody tr {
  border-bottom: 1px solid #cbcbcb;
}

.printPage .table td {
  padding: 4px 6px;
  border-left: 1px solid #cbcbcb;
}

.printPage .table td:last-child {
  border-right: 1px solid #cbcbcb;
}

.printPage .table thead tr th {
  padding: 4px 6px;
  border-width: 1px !important;
  border-color: #cbcbcb !important;
}

.printPage .border-bottom {
  border-bottom: 1px solid #000 !important;
}

.printInfo {
  min-height: 129mm !important;
}

.info-row {
  display: flex;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.mt-3 {
  margin-top: 0.75rem
    /* 12px */
  ;
}

.mb-2 {
  margin-bottom: 0.5rem
    /* 8px */
  ;
}

.pb-2 {
  padding-bottom: 0.5rem
    /* 8px */
  ;
}

.pb-3 {
  padding-bottom: 0.75rem
    /* 12px */
  ;
}

.mb-3 {
  margin-bottom: 0.75rem
    /* 12px */
  ;
}

.my-5 {
  margin-top: 1.25rem
    /* 20px */
  ;
  margin-bottom: 1.25rem
    /* 20px */
  ;
}

.w-36 {
  width: 9rem
    /* 144px */
  ;
}

.text-sm {
  font-size: 12pt !important;
  line-height: 1.25rem
    /* 20px */
  ;
}

@media print {
  .print .container {
    height: 290mm !important;
    padding: 0 !important;
    margin: 0 auto !important;
    font-size: 12pt !important;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .text-sm {
    font-size: 12pt !important;
  }

  .printFontColor {
    color: #000 !important;
  }

  .printFontWeight {
    font-weight: bold;
  }

  .printPage .cut-line {
    border-bottom: 1px dashed #000;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .printPage .table {
    border-color: #000;
  }

  .printPage tbody tr {
    border-bottom: 1px solid #000;
  }

  .printPage .table .table td {
    border-left: 1px solid #000;
    color: #000;
  }

  .printPage .table td:last-child {
    border-right: 1px solid #000;
  }

  .printPage .table thead tr th {
    border: 1px solid #000 !important;
    font-weight: bold !important;
    color: #000;
  }

  .printPage .border-bottom {
    border-bottom: 1px solid #000 !important;
  }

  .printInfo {
    min-height: 129mm !important;
  }
}</style>
