<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click.prevent="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <DateRange triggerType="immediate" :start-date="gridQueryParams.startDate" :end-date="gridQueryParams.endDate"
        date-type="c" @query="e => {
          gridQueryParams.startDate = e.startDate;
          gridQueryParams.endDate = e.endDate;
        }
          " />
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex xl:items-center sm:mr-auto">
          <vxe-select v-model="gridQueryParams.status" clearable placeholder="請選擇狀態" class="mr-2">
            <vxe-option v-for="item in Object.values($enums.OrderStatus)" :key="'hotspot-' + item.Value"
              :value="item.Value" :label="item.Name">
            </vxe-option>
          </vxe-select>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="hideBatchDropDown()">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="onFormSubmit(row, submit)">
              <template #column-member-id="{ data }">
                <SelectBox v-bind="memberSelectOptions" v-model="data.MemberId" />
              </template>
              <template #column-order-status="{ data }">
                <vxe-select v-model="data.Status" transfer placeholder="訂單狀態">
                  <vxe-option v-for="(item, index) in Object.values(
                    $model.enums.OrderStatus
                  )" :label="item.Name" :value="item.Value" :key="'order-status-' + index"
                    :disabled="data.BeforeStatus === 30">
                  </vxe-option>
                </vxe-select>
                <!-- <SelectBox
                  v-bind="memberSelectOptions"
                  v-model="data.MemberId"
                /> -->
              </template>

              <template #column-items="{ data }">
                <div class="intro-y overflow-hidden box border p-3">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.items = !toggleVisible.items">訂單明細</a>
                    <a href="#" @click.prevent="toggleVisible.items = !toggleVisible.items">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.items ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div v-show="toggleVisible.items">
                    <VxeTable ref="itemsGrid" :data="data?.Items" v-bind="itemsGridOptions.config">
                      <VxeColumn field="ProductId" title="商品編號" resizable :edit-render="{}">
                        <template #default="{ row }">
                          <span>{{ row.Product?.Number }}</span>
                        </template>
                        <template #edit="{ row }">
                          <SelectBox v-bind="productSelectOptions" v-model="row.ProductId"
                            @change="value => onProductSelectChange(value, row)" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Product.Name" title="商品名稱" resizable :edit-render="{}">
                        <template #default="{ row }">
                          <span>{{
                            row.Product?.Name ?? row.Name
                          }}</span>
                        </template>
                        <!-- <template #edit="{ row }">
                          <SelectBox
                            v-bind="productSelectOptions"
                            v-model="row.ProductId"
                            @change="value => onProductSelectChange(value, row)"
                          />
                        </template> -->
                      </VxeColumn>
                      <!-- <VxeColumn
                        field="Name"
                        title="名稱"
                        resizable
                        :edit-render="{}"
                      >
                        <template #edit="{ row }">
                          <vxe-input v-model="row.Name" />
                        </template>
                      </VxeColumn> -->
                      <VxeColumn field="Price" title="原價" resizable :edit-render="{}" formatter="formatAmount"
                        align="right">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Price" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Quantity" title="數量" resizable :edit-render="{}" formatter="formatAmount">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Quantity" />
                        </template>
                      </VxeColumn>
                      <!-- <VxeColumn
                        field="Discount"
                        title="折扣金額"
                        resizable
                        :edit-render="{}"
                        formatter="formatAmount"
                        align="right"
                      >
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Discount" />
                        </template>
                      </VxeColumn> -->
                      <VxeColumn field="SalePrice" title="售價" resizable :edit-render="{}" formatter="formatAmount"
                        align="right">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.SalePrice" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Amount" title="金額" resizable :edit-render="{}" formatter="formatAmount"
                        align="right">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Amount" />
                        </template>
                      </VxeColumn>

                      <!-- <VxeColumn
                        title="操作"
                        width="160"
                        resizable
                        align="center"
                      > -->
                      <!-- <template #header>
                          <vxe-button @click="itemsGridOptions.insert"
                            >新增
                          </vxe-button>
                        </template> -->
                      <!-- <template #default="{ row }">
                          <template v-if="itemsGrid.isActiveByRow(row)">
                            <vxe-button @click="itemsGridOptions.save(row)"
                              >確認</vxe-button
                            >
                            <vxe-button @click="itemsGridOptions.cancel(row)"
                              >取消</vxe-button
                            >
                          </template>
                          <template v-else>
                            <vxe-button @click="itemsGridOptions.edit(row)"
                              >編輯</vxe-button
                            >
                            <vxe-button @click="itemsGridOptions.delete(row)"
                              >刪除</vxe-button
                            >
                          </template>
                        </template> -->
                      <!-- </VxeColumn> -->
                    </VxeTable>
                  </div>
                </div>
              </template>
              <template #column-shipping="{ data }" v-if="row.Shipping">
                <div class="intro-y overflow-hidden box border p-3">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="
                      toggleVisible.sender = !toggleVisible.sender
                      ">寄件人資訊</a>
                    <a href="#" @click.prevent="
                      toggleVisible.sender = !toggleVisible.sender
                      ">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.sender ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.sender">
                    <VxeForm>
                      <VxeFormItem title="姓名" field="Shipping.SenderName" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderName" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="Email" field="Shipping.SenderEmail" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderEmail" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="手機" field="Shipping.SenderMobilePhone" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderMobilePhone" />
                        </template>
                      </VxeFormItem>
                    </VxeForm>
                  </div>
                </div>
                <div class="intro-y overflow-hidden box border p-3 mt-5">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="
                      toggleVisible.receiver = !toggleVisible.receiver
                      ">收件人資訊</a>
                    <a href="#" @click.prevent="
                      toggleVisible.receiver = !toggleVisible.receiver
                      ">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.receiver ? 'angle-up' : 'angle-down'
                        " />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.receiver">
                    <VxeForm>
                      <VxeFormItem title="姓名" field="Shipping.ReceiverName" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverName" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="Email" field="Shipping.ReceiverEmail" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverEmail" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="手機" field="Shipping.ReceiverMobilePhone" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverMobilePhone" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="收件地址" span="24">
                        <template #default>
                          <AddressSelectBox v-model="data.Shipping.ReceiverAddress" />
                        </template>
                      </VxeFormItem>
                    </VxeForm>
                  </div>
                </div>
                <div class="intro-y overflow-hidden box border p-3 mt-5">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="
                      toggleVisible.invoice = !toggleVisible.invoice
                      ">發票資訊</a>
                    <a href="#" @click.prevent="
                      toggleVisible.invoice = !toggleVisible.invoice
                      ">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.invoice ? 'angle-up' : 'angle-down'
                        " />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.invoice">
                    <vxe-form>
                      <vxe-form-item title="發票類型" span="24">
                        <template #default>
                          <vxe-select v-model="data.InvoiceType">
                            <vxe-option v-for="item in Object.values($enums.InvoiceType)" :label="item.Name"
                              :value="item.Value" :key="'invoice-type-' + item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </vxe-form-item>
                      <template>
                        <vxe-form-item title="發票抬頭" span="12">
                          <template #default>
                            <vxe-input v-model="data.InvoiceTitle" />
                          </template>
                        </vxe-form-item>
                        <vxe-form-item title="統一編號" span="12">
                          <template #default>
                            <vxe-input v-model="data.InvoiceTaxId" />
                          </template>
                        </vxe-form-item>
                      </template>
                      <!-- <vxe-form-item
                        v-if="
                          data.InvoiceType ===
                            $enums.InvoiceType.Business
                        "
                        title="收件人姓名"
                        span="24"
                      >
                        <template #default>
                          <vxe-input
                            v-model="data.Shipping.InvoiceReceiverName"
                          />
                        </template>
                      </vxe-form-item>
                      <vxe-form-item title="收件人地址" span="24">
                        <template #default>
                          <AddressSelectBox
                            v-model="data.Shipping.InvoiceAddress"
                          />
                        </template>
                      </vxe-form-item> -->
                    </vxe-form>
                  </div>
                </div>
              </template>
              <template #column-payments="{ data }">
                <div class="intro-y overflow-hidden box border p-3 mt-5">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.payments = !toggleVisible.payments">繳費明細</a>
                    <a href="#" @click.prevent="toggleVisible.payments = !toggleVisible.payments">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.payments ? 'angle-up' : 'angle-down'
                        " />
                    </a>
                  </div>
                  <div v-show="toggleVisible.payments">
                    <VxeTable ref="paymentsGrid" :data="data?.Payments" v-bind="paymentsGridOptions.config">
                      <VxeColumn field="Time" title="繳費時間" resizable :edit-render="{}" formatter="formatPaymentTime">
                        <template #edit="{ row }">
                          <vxe-input type="datetime" v-model="row.Time" transfer />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Type" title="繳費方式" resizable :edit-render="{}" formatter="formatPaymentType">
                        <template #edit="{ row }">
                          <vxe-select v-model="row.Type" transfer>
                            <vxe-option v-for="(item, index) in Object.values(
                              $enums.PaymentType
                            )" :key="'payment-type-' + index" :label="item.Name" :value="item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Pan" title="卡/帳號" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input v-model="row.Pan" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Amount" title="金額" resizable :edit-render="{}" formatter="formatAmount">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Amount" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Status" title="繳費狀態" resizable :edit-render="{}" formatter="formatPaymentStatus">
                        <template #edit="{ row }">
                          <vxe-select v-model="row.Status" transfer>
                            <vxe-option v-for="(item, index) in Object.values(
                              $enums.PaymentStatus
                            )" :key="'payment-status-' + index" :label="item.Name" :value="item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </VxeColumn>
                      <!-- <VxeColumn
                        title="操作"
                        width="160"
                        resizable
                        align="center"
                      >
                        <template #header>
                          <vxe-button @click="paymentsGridOptions.insert"
                            >新增
                          </vxe-button>
                        </template>
                        <template #default="{ row }">
                          <template v-if="itemsGrid.isActiveByRow(row)">
                            <vxe-button @click="paymentsGridOptions.save(row)"
                              >確認</vxe-button
                            >
                            <vxe-button @click="paymentsGridOptions.cancel(row)"
                              >取消</vxe-button
                            >
                          </template>
                          <template v-else>
                            <vxe-button @click="paymentsGridOptions.edit(row)"
                              >編輯</vxe-button
                            >
                            <vxe-button @click="paymentsGridOptions.delete(row)"
                              >刪除</vxe-button
                            >
                          </template>
                        </template>
                      </VxeColumn> -->
                    </VxeTable>
                  </div>
                </div>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <!-- <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="updateOrderStatus"
            >
            </vxe-button> -->

            <vxe-button type="submit" status="primary" content="確認" @click="
              $refs.form.validate(errMap => {
                if (errMap === undefined) $refs.form.dispatchEvent('submit');
              })
              ">
            </vxe-button>
            <vxe-button type="button" status="success" content="列印" @click="print">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <PrintModal v-model="modalVisible.print" :data="currentRow" @dismiss="() => (modalVisible.print = false)">
    </PrintModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { commafy } from "xe-utils";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import VXETable, { VxeFormProps, VxeTableProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue";
import DateRange from "@/components/date-select/Range.vue";
import formatDate from "xe-utils/toDateString";
import PrintModal from "./Print.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    DateRange,
    PrintModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const toggleVisible = reactive({
      items: true,
      sender: true,
      receiver: true,
      payments: true,
      invoice: true
    });

    const currentRow = ref({});
    const modalVisible = reactive({
      print: false
    });

    const gridQueryParams = reactive<{
      status: number | null;
      startDate: Date;
      endDate: Date;
    }>({
      status: null,
      startDate: new Date(),
      endDate: new Date()
    });
    gridQueryParams.startDate.setMonth(
      gridQueryParams.startDate.getMonth() - 1
    );

    const gridOptions: GridOptions = {
      title: "訂單",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      stripe: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "MemberName",
          title: "會員姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.OrderStatus).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "OrderSource",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.OrderSource).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        },
        {
          field: "DiscountAmount",
          title: "折扣總額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        },
        {
          field: "Amount",
          title: "訂單金額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        }
      ],
      rowClassName(params) {
        const enums = model?.enums as any;
        return params.row.Status === enums.OrderStatus.PaymentConfirmed.Value
          ? "bg-indigo-100"
          : params.row.Status === enums.OrderStatus.Finished.Value
            ? "bg-gray-200"
            : "";
      },
      promises: {
        query: model
          ? params => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "Time", order: 1 });
            if (gridQueryParams.startDate) {
              params.condition!.and(
                "Time",
                Operator.GreaterThanOrEqual,
                gridQueryParams.startDate
              );
            }
            if (gridQueryParams.endDate) {
              params.condition!.and(
                "Time",
                Operator.LessThan,
                gridQueryParams.endDate
              );
            }
            if (gridQueryParams.status) {
              params.condition!.and(
                "Status",
                Operator.Equal,
                gridQueryParams.status
              );
            }
            return new Promise(resolve => {
              model.dispatch("order/query", params).then(async res => {
                const members = await model.dispatch("member/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    res.data.map((e: any) => e.MemberId)
                  )
                });
                res.data.forEach((e: any) => {
                  e.MemberName = members.find(
                    (i: any) => i.Id === e.MemberId
                  ).Person.Name;
                });
                resolve(res);
              });
            });
          }
          : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model
          ? params => {
            return new Promise((resolve, reject) => {
              model.dispatch("order/save", params).then(
                payload => {
                  if (params.deleteRows && params.deleteRows?.length > 0) {
                    CloudFun.send("info", {
                      subject: "訂單取消",
                      content: "成功"
                    });
                  }
                  resolve(payload);
                },
                reason => {
                  reject(reason);
                }
              );
            });
          }
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "Time",
          title: "下單時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "未輸入則系統自動產生" }
          }
        },
        {
          field: "MemberId",
          title: "會員",
          span: 12,
          slots: { default: "column-member-id" }
        },
        // {
        //   field: "Status",
        //   title: "狀態",
        //   span: 12,
        //   itemRender: {
        //     name: "$select",
        //     options: model
        //       ? Object.values(model.enums.OrderStatus)
        //           .filter(
        //             e =>
        //               e.Value !==
        //               (model.enums.OrderStatus as any).Canceled.Value
        //           )
        //           .map(e => {
        //             return { label: e.Name, value: e.Value };
        //           })
        //       : []
        //   }
        // },
        // {
        //   field: "Status",
        //   title: "狀態",
        //   span: 12,
        //   itemRender: {
        //     name: "$select",
        //     options: model
        //       ? Object.values(model.enums.OrderStatus).map(e => {
        //           return { label: e.Name, value: e.Value };
        //         })
        //       : []
        //   }
        // },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          slots: { default: "column-order-status" }
          // itemRender: {
          //   name: "$select",
          //   options: model
          //     ? Object.values(model.enums.OrderStatus).map(e => {
          //         return { label: e.Name, value: e.Value };
          //       })
          //     : []
          // }
        },
        {
          field: "OrderSource",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.OrderSource).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "DiscountAmount",
          title: "折扣總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "ShippingAmount",
          title: "運費總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "Amount",
          title: "訂單金額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        { span: 24, slots: { default: "column-items" } },
        { span: 24, slots: { default: "column-shipping" } },
        { span: 24, slots: { default: "column-payments" } }
        // { title: "發票資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        // {
        //   field: "InvoiceAddress",
        //   span: 24,
        //   slots: { default: "column-invoice" }
        // }
      ],
      rules: {
        MemberId: [{ required: true }]
        // SaleAmount: [{ required: true }],
        // Amount: [{ required: true }],
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇商品",
      textField: "label",
      valueField: "value",
      transfer: true,
      columns: [
        {
          field: "label",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("product/find", value)
              .then(res => resolve({ label: res.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("product/options", params) // eslint-disable-line
      }
    };

    const itemsGrid = ref<any>({});
    const itemsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: "manual", mode: "row", autoClear: false },
        editRules: {
          ProductId: [{ required: true, message: "必要欄位" }],
          SalePrice: [
            { required: true, type: "number", min: 1, message: "金額須為1以上" }
          ],
          Discount: [{ type: "number", min: 0 }],
          Price: [
            { required: true, type: "number", min: 1, message: "金額須為1以上" }
          ],
          Quantity: [
            { required: true, type: "number", min: 1, message: "數量須為1以上" }
          ]
        },
        footerMethod({ columns, data }) {
          return [
            columns.map((column, columnIndex) => {
              // if (columnIndex === 1) {
              //   const items = data.filter(el => el.SalePrice > 0);
              //   return `原價總額：${commafy(
              //     items.reduce((pre, cur) => pre + cur.SalePrice, 0)
              //   )}`;
              // }
              // if (columnIndex === 2) {
              //   return "";
              // }
              if (columnIndex === 4) {
                const items = data.filter(el => el.Amount < 0);
                return `折扣總額：${commafy(
                  items.reduce((pre, cur) => pre + cur.Amount, 0)
                )}`;
              }
              if (columnIndex === 5) {
                const amount = data.reduce((pre, cur) => pre + cur.Amount, 0);
                return `訂單總計：${commafy(amount < 0 ? 0 : amount)}`;
              }
              // if (columnIndex === 5) {
              //   return commafy(
              //     data.reduce((pre, cur) => pre + cur.SalePrice, 0)
              //   );
              // }
              // if (columnIndex === 5) {
              //   return commafy(
              //     data.reduce(
              //       (pre, cur) => pre + cur.SalePrice * cur.Quantity,
              //       0
              //     )
              //   );
              // }
            })
          ];
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await itemsGrid.value.insert({
          SalePrice: 1,
          Price: 1,
          Discount: 0,
          Quantity: 1
        });
        itemsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        itemsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        itemsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          itemsGrid.value.revertData(row);
          itemsGrid.value.clearActived();
          return;
        }

        if (!row.ProductId || !row.SalePrice || !row.Price || !row.Quantity) {
          itemsGridOptions.delete(row);
          return;
        }

        itemsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await itemsGrid.value.validate(row);
        if (!error) {
          itemsGrid.value.clearActived();
        }
      }
    };

    const paymentsGrid = ref<any>({});
    const paymentsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: "manual", mode: "row", autoClear: false },
        editRules: {
          Time: [{ required: true, message: "必要欄位" }],
          Type: [{ required: true, message: "必要欄位" }],
          // Pan: [{ required: true, message: "必要欄位" }],
          Amount: [{ required: true, message: "必要欄位" }],
          Status: [{ required: true, message: "必要欄位" }]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await paymentsGrid.value.insert({
          Time: new Date(),
          Type: 1,
          Amount: 1,
          Status: 10
        });
        paymentsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        paymentsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        paymentsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          paymentsGrid.value.revertData(row);
          paymentsGrid.value.clearActived();
          return;
        }

        if (!row.Time || !row.Type || !row.Pan || !row.Amount || !row.Status) {
          paymentsGridOptions.delete(row);
          return;
        }

        paymentsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await paymentsGrid.value.validate(row);
        if (!error) {
          paymentsGrid.value.clearActived();
        }
      }
    };

    VXETable.formats.mixin({
      formatAmount({ cellValue }) {
        return commafy(cellValue);
      },
      formatPaymentType({ cellValue }) {
        if (model && cellValue) {
          return Object.values(model.enums.PaymentType).find(
            e => e.Value === cellValue
          )?.Name;
        }
        return "";
      },
      formatPaymentStatus({ cellValue }) {
        if (model && cellValue) {
          return Object.values(model.enums.PaymentStatus).find(
            e => e.Value === cellValue
          )?.Name;
        }
        return "";
      },
      formatPaymentTime({ cellValue }) {
        if (new Date(cellValue).getFullYear() > 2000) {
          return $h.formatDate(
            new Date(cellValue).toString(),
            "YYYY/MM/DD HH:mm:ss"
          );
        }
        return "";
      }
    });

    return {
      grid,
      gridQueryParams,
      gridOptions,
      formOptions,
      memberSelectOptions,
      productSelectOptions,
      toggleVisible,
      itemsGrid,
      itemsGridOptions,
      paymentsGrid,
      paymentsGridOptions,
      modalVisible,
      currentRow
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Status = 0;
        row.OrderSource = 0;
        row.SaleAmount = 0;
        row.DiscountAmount = 0;
        row.Amount = 0;
        row.Shipping = {
          ReceiverAddress: {}
        };
      } else {
        const entity = await this.$model.dispatch("order/find", row.Id);
        Object.assign(row, entity);
        if (row.Items?.length > 0) {
          const itemIds = row.Items.filter((e: any) => e.ProductId).map(
            (e: any) => e.ProductId
          );
          const items = await this.$model.dispatch("product/query", {
            condition: new Condition("Id", Operator.In, itemIds)
          });
          row.Items = row.Items.reduce((pre: any, cur: any) => {
            const item = pre.find((e: any) => e.ProductId === cur.ProductId && e.SalePrice < 0);
            if (item) {
              item.Quantity += cur.Quantity;
              item.Amount += cur.Amount;
            } else {
              cur.ProductName = cur.ProductId && items.find((i: any) => i.Id === cur.ProductId).Name;
              pre.push(cur);
            }
            return pre;
          }, []);
          // row.Items.filter((e: any) => e.ProductId).forEach((e: any) => {
          //   e.ProductName = items.find((i: any) => i.Id === e.ProductId).Name;
          // });
        }
        row.Payments = await this.$model.dispatch("payment/query", {
          condition: new Condition("OrderId", Operator.Equal, row.Id)
        });

        row.BeforeStatus = row.Status;
        row.Shipping.ReceiverAddress = row.Shipping.ReceiverAddress || {};
        row.Shipping.InvoiceAddress = row.Shipping.InvoiceAddress || {};
      }
      this.currentRow = row;
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      row.Items = this.itemsGrid.getTableData().fullData;
      row.Payments = null;
      // if (typeof this.paymentsGrid.getTableData === "function")
      //   row.Payments = this.paymentsGrid.getTableData().fullData;
      // console.log(
      //   "🚀 ~ file: Main.vue ~ line 1083 ~ onFormSubmit ~ Payments",
      //   row
      // );

      // row.Payments.forEach((el: any) => {
      //   el.Order = null;
      // });

      callback();
    },
    async onProductSelectChange(id: number, row: any) {
      const product = await this.$model.dispatch("product/find", id);
      row.Product.Name = product.Name;
      row.Price = product.PurchasePrice;
      row.Discount = product.PurchaseDiscount || 0;
      row.SalePrice = product.SalePice;
      row.Quantity = 1;
      row.Amount = product.SalePice;
    },
    async updateOrderStatus() {
      try {
        const row = this.grid.editingRow;
        console.log(
          "🚀 ~ file: Main.vue:1289 ~ updateOrderStatus ~ grid",
          this.grid
        );
        console.log("🚀 ~ file: Main.vue:1289 ~ updateOrderStatus ~ row", row);

        await this.$model.dispatch("order/changeStatus", {
          id: row.Id,
          status: row.Status
        });
        this.$send("info", { subject: "變更狀態", content: "成功" });
      } catch (failure) {
        this.$send("error", { subject: "執行失敗", content: failure.message });
      }
    },
    async print() {
      this.modalVisible.print = true;
    }
  }
});
</script>
