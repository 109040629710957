
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { commafy } from "xe-utils";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import VXETable, { VxeFormProps, VxeTableProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue";
import DateRange from "@/components/date-select/Range.vue";
import formatDate from "xe-utils/toDateString";
import PrintModal from "./Print.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    DateRange,
    PrintModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const toggleVisible = reactive({
      items: true,
      sender: true,
      receiver: true,
      payments: true,
      invoice: true
    });

    const currentRow = ref({});
    const modalVisible = reactive({
      print: false
    });

    const gridQueryParams = reactive<{
      status: number | null;
      startDate: Date;
      endDate: Date;
    }>({
      status: null,
      startDate: new Date(),
      endDate: new Date()
    });
    gridQueryParams.startDate.setMonth(
      gridQueryParams.startDate.getMonth() - 1
    );

    const gridOptions: GridOptions = {
      title: "訂單",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      stripe: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "MemberName",
          title: "會員姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.OrderStatus).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "OrderSource",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.OrderSource).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        },
        {
          field: "DiscountAmount",
          title: "折扣總額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        },
        {
          field: "Amount",
          title: "訂單金額",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          formatter: ({ cellValue }) => commafy(cellValue)
        }
      ],
      rowClassName(params) {
        const enums = model?.enums as any;
        return params.row.Status === enums.OrderStatus.PaymentConfirmed.Value
          ? "bg-indigo-100"
          : params.row.Status === enums.OrderStatus.Finished.Value
            ? "bg-gray-200"
            : "";
      },
      promises: {
        query: model
          ? params => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "Time", order: 1 });
            if (gridQueryParams.startDate) {
              params.condition!.and(
                "Time",
                Operator.GreaterThanOrEqual,
                gridQueryParams.startDate
              );
            }
            if (gridQueryParams.endDate) {
              params.condition!.and(
                "Time",
                Operator.LessThan,
                gridQueryParams.endDate
              );
            }
            if (gridQueryParams.status) {
              params.condition!.and(
                "Status",
                Operator.Equal,
                gridQueryParams.status
              );
            }
            return new Promise(resolve => {
              model.dispatch("order/query", params).then(async res => {
                const members = await model.dispatch("member/query", {
                  condition: new Condition(
                    "Id",
                    Operator.In,
                    res.data.map((e: any) => e.MemberId)
                  )
                });
                res.data.forEach((e: any) => {
                  e.MemberName = members.find(
                    (i: any) => i.Id === e.MemberId
                  ).Person.Name;
                });
                resolve(res);
              });
            });
          }
          : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model
          ? params => {
            return new Promise((resolve, reject) => {
              model.dispatch("order/save", params).then(
                payload => {
                  if (params.deleteRows && params.deleteRows?.length > 0) {
                    CloudFun.send("info", {
                      subject: "訂單取消",
                      content: "成功"
                    });
                  }
                  resolve(payload);
                },
                reason => {
                  reject(reason);
                }
              );
            });
          }
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "Time",
          title: "下單時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", disabled: true }
          }
        },
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "未輸入則系統自動產生" }
          }
        },
        {
          field: "MemberId",
          title: "會員",
          span: 12,
          slots: { default: "column-member-id" }
        },
        // {
        //   field: "Status",
        //   title: "狀態",
        //   span: 12,
        //   itemRender: {
        //     name: "$select",
        //     options: model
        //       ? Object.values(model.enums.OrderStatus)
        //           .filter(
        //             e =>
        //               e.Value !==
        //               (model.enums.OrderStatus as any).Canceled.Value
        //           )
        //           .map(e => {
        //             return { label: e.Name, value: e.Value };
        //           })
        //       : []
        //   }
        // },
        // {
        //   field: "Status",
        //   title: "狀態",
        //   span: 12,
        //   itemRender: {
        //     name: "$select",
        //     options: model
        //       ? Object.values(model.enums.OrderStatus).map(e => {
        //           return { label: e.Name, value: e.Value };
        //         })
        //       : []
        //   }
        // },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          slots: { default: "column-order-status" }
          // itemRender: {
          //   name: "$select",
          //   options: model
          //     ? Object.values(model.enums.OrderStatus).map(e => {
          //         return { label: e.Name, value: e.Value };
          //       })
          //     : []
          // }
        },
        {
          field: "OrderSource",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.OrderSource).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "DiscountAmount",
          title: "折扣總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "ShippingAmount",
          title: "運費總額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        {
          field: "Amount",
          title: "訂單金額",
          span: 12,
          itemRender: { name: "$input", props: { type: "number" } }
        },
        { span: 24, slots: { default: "column-items" } },
        { span: 24, slots: { default: "column-shipping" } },
        { span: 24, slots: { default: "column-payments" } }
        // { title: "發票資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        // {
        //   field: "InvoiceAddress",
        //   span: 24,
        //   slots: { default: "column-invoice" }
        // }
      ],
      rules: {
        MemberId: [{ required: true }]
        // SaleAmount: [{ required: true }],
        // Amount: [{ required: true }],
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇商品",
      textField: "label",
      valueField: "value",
      transfer: true,
      columns: [
        {
          field: "label",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("product/find", value)
              .then(res => resolve({ label: res.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("product/options", params) // eslint-disable-line
      }
    };

    const itemsGrid = ref<any>({});
    const itemsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: "manual", mode: "row", autoClear: false },
        editRules: {
          ProductId: [{ required: true, message: "必要欄位" }],
          SalePrice: [
            { required: true, type: "number", min: 1, message: "金額須為1以上" }
          ],
          Discount: [{ type: "number", min: 0 }],
          Price: [
            { required: true, type: "number", min: 1, message: "金額須為1以上" }
          ],
          Quantity: [
            { required: true, type: "number", min: 1, message: "數量須為1以上" }
          ]
        },
        footerMethod({ columns, data }) {
          return [
            columns.map((column, columnIndex) => {
              // if (columnIndex === 1) {
              //   const items = data.filter(el => el.SalePrice > 0);
              //   return `原價總額：${commafy(
              //     items.reduce((pre, cur) => pre + cur.SalePrice, 0)
              //   )}`;
              // }
              // if (columnIndex === 2) {
              //   return "";
              // }
              if (columnIndex === 4) {
                const items = data.filter(el => el.Amount < 0);
                return `折扣總額：${commafy(
                  items.reduce((pre, cur) => pre + cur.Amount, 0)
                )}`;
              }
              if (columnIndex === 5) {
                const amount = data.reduce((pre, cur) => pre + cur.Amount, 0);
                return `訂單總計：${commafy(amount < 0 ? 0 : amount)}`;
              }
              // if (columnIndex === 5) {
              //   return commafy(
              //     data.reduce((pre, cur) => pre + cur.SalePrice, 0)
              //   );
              // }
              // if (columnIndex === 5) {
              //   return commafy(
              //     data.reduce(
              //       (pre, cur) => pre + cur.SalePrice * cur.Quantity,
              //       0
              //     )
              //   );
              // }
            })
          ];
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await itemsGrid.value.insert({
          SalePrice: 1,
          Price: 1,
          Discount: 0,
          Quantity: 1
        });
        itemsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        itemsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        itemsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          itemsGrid.value.revertData(row);
          itemsGrid.value.clearActived();
          return;
        }

        if (!row.ProductId || !row.SalePrice || !row.Price || !row.Quantity) {
          itemsGridOptions.delete(row);
          return;
        }

        itemsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await itemsGrid.value.validate(row);
        if (!error) {
          itemsGrid.value.clearActived();
        }
      }
    };

    const paymentsGrid = ref<any>({});
    const paymentsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: "manual", mode: "row", autoClear: false },
        editRules: {
          Time: [{ required: true, message: "必要欄位" }],
          Type: [{ required: true, message: "必要欄位" }],
          // Pan: [{ required: true, message: "必要欄位" }],
          Amount: [{ required: true, message: "必要欄位" }],
          Status: [{ required: true, message: "必要欄位" }]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await paymentsGrid.value.insert({
          Time: new Date(),
          Type: 1,
          Amount: 1,
          Status: 10
        });
        paymentsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        paymentsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        paymentsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          paymentsGrid.value.revertData(row);
          paymentsGrid.value.clearActived();
          return;
        }

        if (!row.Time || !row.Type || !row.Pan || !row.Amount || !row.Status) {
          paymentsGridOptions.delete(row);
          return;
        }

        paymentsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await paymentsGrid.value.validate(row);
        if (!error) {
          paymentsGrid.value.clearActived();
        }
      }
    };

    VXETable.formats.mixin({
      formatAmount({ cellValue }) {
        return commafy(cellValue);
      },
      formatPaymentType({ cellValue }) {
        if (model && cellValue) {
          return Object.values(model.enums.PaymentType).find(
            e => e.Value === cellValue
          )?.Name;
        }
        return "";
      },
      formatPaymentStatus({ cellValue }) {
        if (model && cellValue) {
          return Object.values(model.enums.PaymentStatus).find(
            e => e.Value === cellValue
          )?.Name;
        }
        return "";
      },
      formatPaymentTime({ cellValue }) {
        if (new Date(cellValue).getFullYear() > 2000) {
          return $h.formatDate(
            new Date(cellValue).toString(),
            "YYYY/MM/DD HH:mm:ss"
          );
        }
        return "";
      }
    });

    return {
      grid,
      gridQueryParams,
      gridOptions,
      formOptions,
      memberSelectOptions,
      productSelectOptions,
      toggleVisible,
      itemsGrid,
      itemsGridOptions,
      paymentsGrid,
      paymentsGridOptions,
      modalVisible,
      currentRow
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Status = 0;
        row.OrderSource = 0;
        row.SaleAmount = 0;
        row.DiscountAmount = 0;
        row.Amount = 0;
        row.Shipping = {
          ReceiverAddress: {}
        };
      } else {
        const entity = await this.$model.dispatch("order/find", row.Id);
        Object.assign(row, entity);
        if (row.Items?.length > 0) {
          const itemIds = row.Items.filter((e: any) => e.ProductId).map(
            (e: any) => e.ProductId
          );
          const items = await this.$model.dispatch("product/query", {
            condition: new Condition("Id", Operator.In, itemIds)
          });
          row.Items = row.Items.reduce((pre: any, cur: any) => {
            const item = pre.find((e: any) => e.ProductId === cur.ProductId && e.SalePrice < 0);
            if (item) {
              item.Quantity += cur.Quantity;
              item.Amount += cur.Amount;
            } else {
              cur.ProductName = cur.ProductId && items.find((i: any) => i.Id === cur.ProductId).Name;
              pre.push(cur);
            }
            return pre;
          }, []);
          // row.Items.filter((e: any) => e.ProductId).forEach((e: any) => {
          //   e.ProductName = items.find((i: any) => i.Id === e.ProductId).Name;
          // });
        }
        row.Payments = await this.$model.dispatch("payment/query", {
          condition: new Condition("OrderId", Operator.Equal, row.Id)
        });

        row.BeforeStatus = row.Status;
        row.Shipping.ReceiverAddress = row.Shipping.ReceiverAddress || {};
        row.Shipping.InvoiceAddress = row.Shipping.InvoiceAddress || {};
      }
      this.currentRow = row;
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      row.Items = this.itemsGrid.getTableData().fullData;
      row.Payments = null;
      // if (typeof this.paymentsGrid.getTableData === "function")
      //   row.Payments = this.paymentsGrid.getTableData().fullData;
      // console.log(
      //   "🚀 ~ file: Main.vue ~ line 1083 ~ onFormSubmit ~ Payments",
      //   row
      // );

      // row.Payments.forEach((el: any) => {
      //   el.Order = null;
      // });

      callback();
    },
    async onProductSelectChange(id: number, row: any) {
      const product = await this.$model.dispatch("product/find", id);
      row.Product.Name = product.Name;
      row.Price = product.PurchasePrice;
      row.Discount = product.PurchaseDiscount || 0;
      row.SalePrice = product.SalePice;
      row.Quantity = 1;
      row.Amount = product.SalePice;
    },
    async updateOrderStatus() {
      try {
        const row = this.grid.editingRow;
        console.log(
          "🚀 ~ file: Main.vue:1289 ~ updateOrderStatus ~ grid",
          this.grid
        );
        console.log("🚀 ~ file: Main.vue:1289 ~ updateOrderStatus ~ row", row);

        await this.$model.dispatch("order/changeStatus", {
          id: row.Id,
          status: row.Status
        });
        this.$send("info", { subject: "變更狀態", content: "成功" });
      } catch (failure) {
        this.$send("error", { subject: "執行失敗", content: failure.message });
      }
    },
    async print() {
      this.modalVisible.print = true;
    }
  }
});
